body {
  font-family: Roboto, Helvetica,-apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  padding: 0px;
  margin: 0px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}


/*
#####
# CKEditor
#####
*/
.ck.ck-balloon-panel {
  z-index: 1060 !important;
}
.ck-editor__editable {
  min-height: 400px;
}
/*
a:link {text-decoration: none; color:darkblue;}
a:visited {text-decoration: none; color:darkblue;}
a:active {text-decoration: none; color:darkblue;}
a:hover {text-decoration: underline;}

a.inverted {
  color: white;
}
*/

/*
a.btn-default {
  color: #333;
}
a.btn-primary {
  color: white;
}
a.btn-danger {
  color: white;
}
a.btn-success {
  color: white;
}

a.link-warning {
  color: #8a6d3b;
}
a.link-danger {
  color: #a94442;
}
*/

/*
h1,.h1 {
  font-size: 24px;
  color: #777777;
}
h2,.h2 {
  font-size: 20px;
  color: #777777;
}
h3,.h3 {
  font-size: 16px;
  color: #777777;
  margin-bottom: 5px;
}
*/
h1.app_name {
  color: rgba(0,0,0,0.7);
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 28px;
}

.fa-6x {
  font-size: 8em;
}

hr {
  border: 1px solid #DDDDDD;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.semi-bold {
  font-weight: 600;
}

.header-treasures {
  color: #606970 !important;
  border-bottom: 1px solid #606970 !important;
}

.header-apply {
  color: #c18626 !important;
  border-bottom: 1px solid #c18626 !important;
}

.header-living {
  color: #961526 !important;
  border-bottom: 1px solid #961526 !important;
}

.main-subtitles {
  color: #37474f;
}


/*
#####
# Navbar
#####
*/

.navbar {
  background-color: #37474f;
  //background-color: #263238;
  font-size: 16px;

  .navbar-brand {
    font-size: 16px;
  }
}

/*
.navbar {
  border-radius: 0px;
  background-color: rgba(0,0,0,0.7);
  margin-bottom: 10px;
}
.navbar-inverse .navbar-brand {
  font-size: 14px;
}
.navbar-inverse .navbar-nav > li > a {
  color: #eee;
}
*/

/*
#####
# Container
#####
*/
.main-container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

/************************************************/
/* Sidebar                                      */
/************************************************/

.wrapper {
   display: flex;
   align-items: stretch;
   width: 100%;
}

#sidebar {
  margin-left: -300px;
  width: 300px;
  position: fixed;
  height: 100vh;
  z-index: 999;
  background-color: #78909c;
  transition: all 0.3s;
  padding: 40px 10px 20px 10px;
  overflow-y: auto;
}

#sidebar.active {
  margin-left: 0;
}

#content {
  margin-left: 300px;
  padding: 0px;
  width:100%;
}

#overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width:99vw;
  display: none;
  transition: all 0.3s;
}

#overlay.active {
  display: block;
    background: rgba(0, 0, 0, .2);
}

@media (min-width: 992px) {
  #sidebar {
    margin-left: 0;
  }

  #overlay.active {
    display: none;
  }
}

@media (max-width: 991.98px) {
  #sidebar {
    margin-left: -300px;
    box-shadow: 0;
  }
  #sidebar.active {
    margin-left: 0;
    box-shadow: -15px 0 20px 20px #333;
  }

  #content {
    margin-left: 0px;
  }
}


.list-group.watchtower_study {
  max-width: 500px;
}


/*
#####
# Table
#####
*/

.table-nonfluid {
   width: auto !important;
}

.member_add th, .member_add td {
  border-top: none !important;
}
.programs_list {
  border: none !important;
  width: auto !important;
  min-width: 400px;
}
.publishers_list tr {
  height: 35px !important;
}
.publishers_list td {
  padding: 5px;
  white-space:nowrap;
}
.fsgroups_list {
  width: auto !important;
}

.fsg-publishers {
  min-height: 500px;

  .dropdown.bootstrap-select {

    .dropdown-menu {
      max-height: 420px !important;
    }
  }
}

table.home {
  max-width: 600px;
}

/*
#####
# Banner
#####
*/
.alert {
  margin: 0;
  display: none;
  border-radius: 0;
}

/*
#####
# Labels
#####
*/
.control-label {
 font-weight: 600;
}

/*
#####
# Span
#####
*/
span.assign_header {
  font-size: 18px;
  color: #777777;
}

/*
#####
# Tabs
#####
*/
#advanced_permissions_content {
  padding: 20px;
}
#advanced_permissions_content .checkbox {
  padding: 0px 0px 0px 10px;
  margin: 0px;
}
#advanced_permissions_content .form-group {
  margin: 5px 0px 0px 0px;
}

/*
#####
# Sidebar
#####
*/

.sidebar {
  .header {
    cursor: auto;
  }

  li {
    cursor: pointer;
    cursor: hand;
    color: white;
  }

  ul {

    li {
      cursor: pointer;
      cursor: hand;
      color: white;
    }
  }
}

.sidebar {

  .nav-link {
    font-size: 1.3rem;
    color: white;
  }

  .submenu .nav-link {
    font-size: 1.2rem;
  }

  i {
    font-size: 1.3rem;
  }
}

.sidebar

/*
#####
# Content
#####
*/

DIV.base {
  padding: 0px;
  margin: 0px;
}

DIV.login {
  background-color: white;
  margin-left: -165px;
  position: absolute;
  top: 100px;
  left: 50%;
  width: 330px;
  padding: 25px 20px 10px 20px;
  text-align: right;
  vertical-align: bottom;
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
  border-radius:2px
}

/*
#####
# Layout
#####
*/

div.layout .form-group {
  max-width: 200px;
}

/*
DIV#main {
  vertical-align: top;
  padding: 15px;
}
DIV#menu {
  background-color: white;
  padding: 20px 10px 20px 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.4);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

DIV.menuitem {
  width: 160px;
  hight: 15px;
  padding: 5px;
  border: 1px solid #dddddd;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: 3px;
  cursor: pointer;
  cursor: hand;
  font-size: 15px;
  font-weight: 400;
}
DIV.menuitem a {
  color: black;
  text-decoration: none;
}
DIV.menusubitem {
  margin-left:10px;
  padding: 1px 0px 3px 3px;
  border-left: 5px solid gray;
}
DIV.menusubitem:hover {
  background-color: #91a3ef;
}
DIV.submenuitem a {
  color: black;
}
*/

DIV.add_pub {
  padding: 20px;
  border: 1px solid #BBBBBB;
}
DIV.notice {
  color: red;
  padding-top: 10px;
  padding-bottom: 10px;
}
DIV.absences {
  width: 90%;
  vertical-align: top;
  padding: 10px 5px 10px 5px;
}

DIV.programs {
  width: 90%;
  vertical-align: top;
  padding: 10px 5px 10px 5px;
}
DIV.congregation_info {
  width: 90%;
  vertical-align: top;
  padding: 10px 5px 10px 5px;
}
DIV.add_member {
  border: 1px solid darkgrey;
  padding: 3px;
  width: 150px;
  text-align: center;
}

div#loading {
  position: fixed;
  text-align: center;
  padding: 0px;
  width: 50px;
  height: 40px;
  top: 8px;
  left: 50%;
  margin-left: -25px;
  z-index: 2000;
}

DIV#contact {
  position: absolute;
  top: 100px;
  width: 550px;
  left: 150px;
  padding: 20px 20px 0px 20px;
  background-color: white;
  border: 3px solid #AAAAAA;
}
DIV#busy {
  border: 1px solid #AAAAAA;
  width: 250px;
  position: fixed;
  top: 100px;
  /*left: 50%-125px;*/
  background-color: white;
  padding: 5px;
}
div.chosen-container a.chosen-single-with-drop{
  z-index:19;
}
div.modal-content {
  border-radius: 2px;
}

/*
.borderless tbody tr td, .borderless tbody tr th, .borderless thead tr th {
  border: none;
}
TABLE.maintable {
  padding: 0px;
  margin: 0px;
  border-collapse: collapse;
}
TABLE.starttime td {
  vertical-align: bottom;
}
TABLE.pubselection {
  width: 380px;
}
TABLE.pubselection a {
  font-weight: bold;
}
TABLE.pubselection td {
  border-bottom: 1px dotted #888888;
}
TABLE.pubselection th {
  font-size: 12px;
}

TABLE.cong_attributes {
  font-size: 12px;
  font-weight: bold;
  width: 100%;
}
TABLE.programs TH {
  font-size: 13px;
  border-bottom: 1px dotted #888888;
}
TABLE.programs TD {
  vertical-align: top;
}
TABLE.congregation_info TD {
  vertical-align: top;
  padding-right: 10px;
}
TABLE.check_pubs {
  font-size: 12px;
  border: 1px dashed grey;
  width: 250px;
}
TABLE.check_pubs TD {
  border-bottom: 1px dotted #888888;
}
TABLE.show_pubs {
  border: 1px solid #dddddd;
  width: 100%;
}
TABLE.publishers_list {
  border: 1px solid #dddddd;
  width: 100%;
}
TABLE.publishers_list a {
  color: #333;
}
TABLE.publishers_list tr {
  line-height: 20px;
}
TABLE.publishers_list td {
  border-bottom: 1px dotted #888888;
}
TABLE.families_list {
  border: 1px solid #dddddd;
  width: 50%;
}
TABLE.families_list a {
  color: #333;
}

TABLE.families_list td {
  border-bottom: 1px dotted #888888;
}
TABLE.congregations_list a {
  color: #555;
}
TABLE.congregations_list tr {
  line-height: 20px;
}
TABLE.congregations_list td {
  border-bottom: 1px dotted #888888;
}
TABLE.weeks_list {
  border: 1px solid #dddddd;
}
TABLE.weeks_list a {
  color: #555;
}
TABLE.weeks_list tr {
  line-height: 20px;
}
TABLE.publishers_list td, TABLE.families_list td, TABLE.congregations_list td, TABLE.weeks_list td, {
  border-bottom: 1px dotted #888888;
}

TABLE.programs_list a {
  color: #333;
}
TABLE.programs_list tr {
  line-height: 20px;
}

TABLE.fsgroups_list a {
  color: #333;
}
TABLE.fsgroups_list tr {
  line-height: 20px;
}
TABLE.ts_students {
  border: 1px solid #dddddd;
}
TABLE.show_inactive_pubs {
  font-size: 12px;
  border: 1px dashed grey;
}
TABLE.show_logs {
  border: 1px solid #dddddd;
  width: 600px;
}
TABLE.program_layout {
  border: 1px solid #dddddd;
  width: 400px;
}
TABLE.show_progs {
  border: 1px solid #dddddd;
  width: 400px;
}
TABLE.show_pubs td {
  border-bottom: 1px dotted #888888;
}

TABLE.ts_students td {
  border-bottom: 1px dotted #888888;
}
TABLE.show_inactive_pubs td {
  border-bottom: 1px dotted #888888;
}
TABLE.show_logs td {
  border-bottom: 1px dotted #888888;
}
TABLE.show_members {
  font-size: 12px;
  border: 1px solid #AAAAAA;
  width: 90%;
}
TABLE.modify_material {
  border: 1px solid #dddddd;
  width: 500px;
}
TABLE.modify_material TH {
  color: #777777;
  font-size: 16px;
}
TABLE.modify_material TD {
  vertical-align: top;
  border-bottom: 1px solid #AAAAAA;
}
TABLE.aco_settings {
  font-size: 12px;
  border: 1px solid #AAAAAA;
}
TABLE.aco_settings td {
  vertical-align: top;
}
TABLE.absences_list {
  border: 1px solid #dddddd;
  width: 100%;
}
TABLE.absences_list a {
  color: #333;
}
TABLE.absences_list tr {
  line-height: 20px;
}
TABLE.absences_list td {
  border-bottom: 1px dotted #888888;
}

TABLE.events_list {
  border: 1px solid #dddddd;
  width: 100%;
}
TABLE.events_list a {
  color: #333;
}
TABLE.events_list tr {
  line-height: 20px;
}
TABLE.events_list td {
  border-bottom: 1px dotted #888888;
}

TABLE.informations_list {
  border: 1px solid #dddddd;
  width: 100%;
}
TABLE.informations_list a {
  color: #333;
}
TABLE.informations_list tr {
  line-height: 20px;
}
TABLE.informations_list td {
  border-bottom: 1px dotted #888888;
}

TABLE.login {
  padding: 0px;
  margin: 0px;
}
TABLE.login tr {
  height:30px;
}
TABLE.login td {
  line-height:22px;
}

TABLE.cong_settings td {
  vertical-align:bottom;
}
TABLE.week_material {
  border: 1px solid #dddddd;
}
TABLE.week_material td {
  border-bottom: 1px dotted #888888;
}
TABLE.publictalks {
  border: 1px solid #dddddd;
}
TABLE.contact_header {
  width: 100%;
  padding: 0px;
  margin: 0px;
  border-collapse: collapse;
}
TD.main {
  vertical-align: top;
  padding: 10px;
  width: 100%;
}
TD.left {
  width: 150px;
  font-size: 14px;
  vertical-align: top;
  border-collapse: collapse;
  margin: 0px;
  padding: 0px;
  padding-top: 10px;
}
TD {
  vertical-align: top;
}

TABLE.prog_data_bstssm {
  font-size: 12px;
  width: 100%;
  border-collapse: collapse;
}

TABLE.prog_data {
  border: 1px solid #dddddd;
  width: 100%;
}
TABLE.prog_data td {
  border-bottom: 1px dotted #888888;
}

table.tms_showStudent {
  font-size:14px;
}
table.tms_showStudent td {
  border-bottom: 1px dotted #888888;
}
div.school_number_done {
  padding: 1px;
}
div.school_number_active a {
  color: white;
}

table.publisher {
  background-color: white;
}
*/

INPUT {
  font-size:14px;
  border: 1px solid #AAAAAA;
  padding: 1px 2px 1px 2px;
}
span.grey {
  color: #777777;
}
span.copy {
  color: grey;
  font-size: 10px;
}

.fsg_show_publishers {
  cursor: hand;
  cursor: pointer;
}

.sortable {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
div.drag-handle {
  cursor: ns-resize;
}
.sortable li {
  margin: 0 2px 2px 2px;
  padding: 2px;
  padding-left: 20px;
}
